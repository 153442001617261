// Angular
import { Injectable, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';

import { AuthService } from '../../../auth/_services/auth.service';

@Injectable()
export class MenuConfigService implements OnDestroy, OnInit {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;

  // uprawnienie do zarządzania kr lub rks
  restrictedArea: boolean;
  // Dostęp dla operatora
  restrictedToOperatorAccess = false;
  // Dostęp dla admina
  restrictedToAdminAccess = false;

  // rola kr lub rks
  // restrictedToKrOrRks = false;

  /**
   * Service Constructor
   */
  constructor(private authService: AuthService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig;
  }

  getRole() {
    this.authService.getRole().subscribe((roles) => {
      if (roles.includes('manageKrAndRks')) {
        this.restrictedArea = true;
      }
      // if (
      //   roles.includes('kr') ||
      //   roles.includes('rks') ||
      //   roles.includes('pracownik')
      // ) {
      //   this.restrictedToKrOrRks = true;
      // }
      if (roles.includes('operator')) {
        this.restrictedToOperatorAccess = true;
        this.restrictedToAdminAccess = true;
      }
      if (roles.includes('admin')) {
        this.restrictedToAdminAccess = true;
      }
    });
  }

  ngOnInit() {}

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.restrictedArea = false;
    this.restrictedToOperatorAccess = false;
    this.restrictedToAdminAccess = false;
    this.getRole();
    if (this.menuConfig) {
      this.menuConfig = '';
    }
    this.menuConfig = config;
    // Ukrycie KR i RKS dla ról, które nie mają manageKrAndRks
    if (!this.restrictedArea === true) {
      this.menuConfig.header.items = this.menuConfig.header.items.filter(
        (item) => item.title != 'KR' && item.title != 'RKS'
      );
      this.menuConfig.aside.items = this.menuConfig.aside.items.filter(
        (item) => item.title != 'KR' && item.title != 'RKS'
      );
    }
    // if (!this.restrictedToKrOrRks === true) {
    //   this.menuConfig.header.items = this.menuConfig.header.items.filter(
    //     (item) => item.title != 'Instrukcja'
    //   );
    //   this.menuConfig.aside.items = this.menuConfig.aside.items.filter(
    //     (item) => item.title != 'Instrukcja'
    //   );
    // }
    if (!this.restrictedToAdminAccess) {
      this.menuConfig.header.items = this.menuConfig.header.items.filter(
        (item) => item.title != 'Sensory'
      );
      this.menuConfig.aside.items = this.menuConfig.aside.items.filter(
        (item) => item.title != 'Sensory'
      );
      this.menuConfig.header.items = this.menuConfig.header.items.filter(
        (item) => item.title != 'Zgłoszenia',
      );
      this.menuConfig.aside.items = this.menuConfig.aside.items.filter(
        (item) => item.title != 'Zgłoszenia'
      );
    }
    if (!this.restrictedToOperatorAccess) {
      this.menuConfig.header.items = this.menuConfig.header.items.filter(
        (item) => item.title != 'Diagnostyka',
      );
      this.menuConfig.aside.items = this.menuConfig.aside.items.filter(
        (item) => item.title != 'Diagnostyka'
      );
    }

    this.onConfigUpdated$.next(this.menuConfig);
  }
  ngOnDestroy() {}
}
